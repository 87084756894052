var prj_config = {

    current_unit :'원',

    // ***************************************************
    // For Test 
    // name: 'procut',
    // production: 'new procution',
    // GOOGLE_API: 'AIzaSyBw_tykpsZH0K_trehtgbgbgfgs',
    // BASE_URL:'http://test.cafe24.com:1337/v1'
  }
  

export default prj_config;