<template>
  <div>
    <!-- rounded-lg -->
    <div class="home" v-if="$store.getters.getUserData">
      <div class="home_pay">
        <div class="user_info">
          <h2>안녕하세요, {{ userName }}님!</h2>
          <p>매일 출첵 보상금이 지급됩니다</p>
        </div>

        <div class="user_pay" @click="touchCard">
          <h3>{{ userName }}님의 지갑</h3>
          <p>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.55713 6.9783H12.8807"
                stroke="#FF3E3E"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.55713 10.0315H12.8807"
                stroke="#2B2B2B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.55713 13.0847H10.3269"
                stroke="#2B2B2B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.864 3H4.574C3.7047 3 3 3.71103 3 4.58814V15.4748C3 16.352 3.7047 17.063 4.574 17.063H14.864C15.7333 17.063 16.438 16.352 16.438 15.4748V4.58814C16.438 3.71103 15.7333 3 14.864 3Z"
                stroke="#2B2B2B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            통장이용내역
          </p>
        </div>

        <v-card color="primary" class="pay_con">
          <div class="amount">
            <h2>Wonthe Pay</h2>
            <p>
              {{ formatPrice(cash) }}
              <span> {{ currentUnit }}</span>
            </p>
          </div>

          <div class="btn">
            <v-btn plain @click="wallet_charge">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 12.7297C1.5 13.2119 1.89995 13.6 2.39697 13.6H15.603C16.1001 13.6 16.5 13.2119 16.5 12.7297V7.57175H1.5V12.7297Z"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 4.85904H1.5V7.57175H16.5V4.85904Z"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 2.47034C16.5 1.98807 16.1001 1.60001 15.603 1.60001H2.39697C1.89995 1.60001 1.5 1.98807 1.5 2.47034V4.85903H16.5V2.47034Z"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.33447 10.4841H6.66427"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              충전
            </v-btn>
            <v-btn plain @click="send_money">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.71997 4.03998H14.6"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.04002 6.67999L1.40002 4.04107L4.04002 1.39999"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.28 11.96H1.40003"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.96 14.6L14.6 11.9611L11.96 9.32001"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              송금
            </v-btn>
          </div>

          <!-- <div v-if="stateChange == 'main'">
            <v-row no-gutters class="mt-3 mb-3 text-center" v-if="showWithdraw == false">
              <v-col>
                <div @click="wallet_charge">
                  <p class=" text-subtitle-2 grey--text text--lighten-3"> 충전 </p>
                </div>
              </v-col>
    
              <v-col>
                <div @click="send_money">
                  <p class=" text-subtitle-2 grey--text text--lighten-3"> 송금 </p>
                </div>
              </v-col>
    
              <v-col>
                <div class="text-xs-button" @click="touchCard">
                  <p class="text-subtitle-2 grey--text text--lighten-3"> 통장이용내역 </p>
                </div>
              </v-col>
            </v-row>
    
            <v-row v-if="showWithdraw == true" class="mt-3 mb-3 text-center">
              <v-col>
                <div class="text-xs-button" @click="login">
                  <p class="text-subtitle-2 white--text text--darken-2">
                    로그인ㅇㅇ
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
    
          <v-row no-gutters class="mt-3 mb-3 text-center" v-if="stateChange != 'main'">
            <v-col cols="12">
              <v-btn text class="white--text mb-3" @click="checkWithdraw">
                출금신청
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card>
      </div>
    </div>

    <div class="main" v-else>
      <div class="main_pay">
        <v-card class="pay_con">
          <v-card-text class="amount text-h5">
            <div class="white--text">Wonthe Pay</div>
          </v-card-text>

          <div class="btn">
            <v-btn @click="login">로그인 하기</v-btn>
          </div>
        </v-card>
      </div>

      <div class="main_gifticon">
        <div class="banner" @click="goBlog">
          <img src="/imgs/banner.png" alt="배너이미지" width="100%" />
        </div>

        <!-- <div class="add_btn">
          <button>
            기프티콘 추가
            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="4.5" y1="1.96701e-08" x2="4.5" y2="9" stroke="#666666"/>
              <line x1="9" y1="4.5" y2="4.5" stroke="#666666"/>
            </svg>
          </button>
        </div> -->
      </div>
    </div>

    <v-overlay :value="loading_bar">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "../../service/axios";
import prj_config from "../../helper/global_config";

export default {
  name: "Wallet",
  props: {
    toWallet: {
      type: Number,
      require: false,
    },
    // position: {
    //     type: String,
    //     require: false
    // }
  },

  data() {
    return {
      userName: "사용자",
      // showWithdraw: false,
      deposit_type: false,
      currentUnit: "달러",
      cash: 0,
      loading_bar: false,
    };
  },

  watch: {
    cash() {
      const cash = this.$store.getters.getBalance;
      return cash;
    },
  },

  computed: {
    stateChange() {
      let subPageName = this.$store.getters.getSubPage;
      console.log("stateChange....... : ", subPageName);

      if (subPageName == "통장") {
        return "통장";
        // console.log('Where am I ? ', subPageName)
      } else {
        return "main";
      }
    },

    showWithdraw() {
      let flag = this.$store.getters.getUserData;
      let _this = this;
      console.log("Flag : ", flag);
      if (flag) {
        if (flag.bank_holder) _this.userName = flag.bank_holder;
        return false;
      } else return true;
    },

    // getSubPageData() {
    //   let title = this.$store.getters.getSubPage;
    //   // let stateroute = this.$route
    //   console.log("This route title ........................: ", title);
    //   // console.log('This route state ........................: ', stateroute)
    //   return null;
    // },

    // cash() {
    //     // let tempCash = 0
    //     // if(this.toWallet !== 0) {
    //     //     tempCash = this.toWallet
    //     // }
    //     const cash = this.$store.getters.getBalance
    //     return cash
    // },
  },

  async created() {
    // var loginState = this.$store.getters.getUserData;

    const token = this.$cookies.get("token");
    // console.log('Token : ', token)

    if (token) {
      // console.log("Prop data this.toWallet : ", this.toWallet);
      // console.log("this.$store.getters.getUserData: ", this.$store.getters.getUserData);
      if (this.$store.getters.getUserData) {
        // console.log('%c Not null', 'color:red;')
        let userName = this.$store.getters.getUserData.user_name;
        if (userName == "") this.userName = "사용자";
        else this.userName = userName;
      } else {
        // console.log('%c null', 'color:red;')
      }

      this.cash = this.$store.getters.getBalance;
      // console.log("this.cash  : ", this.cash);
    } else {
      // this.cash = 0;
      this.$store.commit({ type: "setUserData", userData: null });
      this.$store.commit({ type: "setBalance", balance: 0 });
    }
  },

  mounted() {
    let global_config = prj_config;
    // console.log('Global ..............: ', global_config)
    this.currentUnit = global_config.current_unit;
    // const token = this.$cookies.get("token");
    // console.log("Token : ", token);
  },

  methods: {
    goBlog() {
      let path = "https://blog.naver.com/onethecon/222724888732";
      window.open(path, "_blank");
      // console.log("Go Blog...........");
    },
    setPreview(item) {
      // console.log('Item : ', item )
      // this.preview_item = item;
      // this.preview_dialog = true;
      // this.barcodeNumber = item.barcode
      this.$store.commit({ type: "setSubPage", sub_page: "상품상세" });
      this.$router
        .push({ name: "gift_preview", params: { item: item } })
        .catch(() => {}); // item: {gift}
    },

    checkWithdraw() {
      if (this.cash >= 20000) {
        this.goWithdraw();
        // console.log('High cash : ', this.cash)
      } else {
        // console.log('Low cash : ', this.cash)
        alert("출금은 20,000원 이상부터 가능합니다");
      }
    },

    touchCard() {
      const token = this.$cookies.get("token");
      // console.log('Token : ', token)

      if (token != null) {
        // console.log('Go Ledger.....')
        this.$store.commit({ type: "setSubPage", sub_page: "통장" });
        this.$router.push("/detail/ledger");
      } else {
        this.login();
      }
    },

    formatPrice(value) {
      try {
        return value
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          .trim();
      } catch {
        return value;
      }
    },

    goWithdraw() {
      if (this.$cookies.get("token") != null) {
        this.$store.commit({ type: "setSubPage", sub_page: "출금" });
        this.$router.push("/withdraw/withdraw");
      } else {
        this.loading_bar = true;
        this.login();
      }
    },

    async depositType() {
      let options = {
        deposit_type: "출금신청",
      };
      const params = {
        accessKey: this.$cookies.get("token"),
        options: options,
      };
      await axios
        .post("/payment/depositTypeInfo", params)
        .then((res) => {
          let data = res.data.data.item;
          if (data != null) {
            this.deposit_type = true;
          } else {
            this.deposit_type = false;
          }
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
    },

    wallet_charge() {
      // console.log('Add money..........')
      if (this.$cookies.get("token") != null) {
        this.$store.commit({
          type: "setSubPage",
          sub_page: "충전",
        });
        this.$router.push("/payments/AddMoney");
      } else {
        this.loading_bar = true;
        this.login();
      }
    },

    send_money() {
      // console.log('send_money..........')
      if (this.$cookies.get("token") != null) {
        this.$store.commit({
          type: "setSubPage",
          sub_page: "송금",
        });
        this.$router.push("/payments/SendMoney");
      } else {
        this.loading_bar = true;
        this.login();
      }
    },

    login() {
      this.$store.commit({ type: "setSubPage", sub_page: "로그인" });
      this.$router.push("/sign/login");
    },
  },
};
</script>

<style lang="scss">
.wallet_title {
  font-size: 0.7rem;
  letter-spacing: -0.07em;
}

// mixin
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$color_main: #fe9900;

.main {
  padding: 28px 0px 30px;

  &_pay {
    width: 100%;
    height: auto;
    padding: 0 20px 40px;

    .pay_con {
      width: 100%;
      height: 170px;
      border-radius: 20px;
      overflow: hidden;

      .amount {
        width: 100%;
        height: 110px;
        background: $color_main;
        @include flex-center;

        div {
          font-size: 1.25rem;
          color: #fff;
          font-weight: 700;
        }
      }
    }

    .btn {
      width: 100%;
      height: auto;
      background: #fe7a00;

      .v-btn {
        width: 100%;
        height: 60px !important;
        color: #fff;
        font-size: 0.938rem;
        background: #fe7a00 !important;
      }
    }
  }

  &_gifticon {
    width: 100%;
    height: auto;
    padding: 0 20px;

    .banner {
      width: 100%;
      height: auto;
      padding-bottom: 20px;
    }

    .add_btn {
      width: 100%;
      height: auto;

      button {
        width: 100%;
        height: 45px;
        border: 1px dashed #ccc;
        border-radius: 8px;
        font-size: 0.875rem;
        color: #666;
      }
    }
  }
}

.home {
  padding: 30px 0px 0px;

  &_pay {
    width: 100%;
    height: auto;
    padding: 0 20px 40px;

    .user_info {
      width: 100%;
      height: auto;
      padding-bottom: 35px;

      h2 {
        font-size: 1.375rem;
        padding-bottom: 12px;
      }

      p {
        font-size: 0.938rem;
        color: #777;
      }
    }

    .user_pay {
      width: 100%;
      height: auto;
      padding-bottom: 20px;
      @include flex-between;

      h3 {
        font-size: 1.0625rem;
        font-weight: 600;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        @include flex-center;
        gap: 2px;
        margin-bottom: 0;
      }
    }

    .pay_con {
      width: 100%;
      height: 170px;
      border-radius: 20px;
      overflow: hidden;

      .amount {
        width: 100%;
        height: 110px;
        background: $color_main;
        @include flex-column;

        h2 {
          font-size: 0.875rem;
          color: #fff;
          padding: 15px 20px;
          font-weight: 700;
        }

        p {
          width: 100%;
          height: 100%;
          font-size: 1.5rem;
          font-weight: 600;
          color: #fff;
          text-align: center;

          span {
            font-size: 0.875rem;
          }
        }
      }

      .btn {
        width: 100%;
        height: auto;
        background: #fe7a00;
        display: flex;

        .v-btn {
          flex: 1;
          height: 60px;
          color: #fff;
          background: #fe7a00;
          font-size: 0.938rem;
          @include flex-center;
          gap: 8px;
          position: relative;

          &:first-child::after {
            content: "";
            position: absolute;
            right: 0;
            width: 1px;
            height: 15px;
            background: rgba($color: #ffffff, $alpha: 0.5);
          }

          .v-btn__content {
            opacity: 1 !important;
          }

          svg {
            margin-right: 7px;
          }
        }
      }
    }
  }

  &_gifticon {
    width: 100%;
    height: auto;
    padding: 0 20px 0px;

    .banner {
      width: 100%;
      height: auto;
      padding-bottom: 20px;
    }

    .slider {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
      @include flex-column-center;

      .carousel-3d-slider {
        width: 275px !important;
        height: 150px !important;

        .carousel-3d-slide {
          border-radius: 20px;
          border: unset;

          &.add-gifticon {
            border: 1px dashed #ccc !important;
            border-radius: 8px !important;
            font-size: 1rem;
            color: #666;
            @include flex-column-center;
            gap: 12px;
          }

          div {
            color: #fff;
            font-weight: 400;
            font-size: 17px;
            line-height: 30px;
            text-align: center;
            letter-spacing: -0.01em;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      p {
        padding-top: 15px;
        font-size: 0.875rem;
        font-weight: 600;
        color: #666;

        span {
          color: #333;
        }
      }
    }

    .history {
      width: auto;
      height: auto;
      padding-bottom: 30px;

      button {
        width: 275px;
        height: 40px !important;
        margin: 0 auto;
        background: #f5f5f5;
        border-radius: 10px;
        padding: 0 15px;
        @include flex-between;

        p {
          font-size: 0.875rem;
          font-weight: 700;
          color: #555;
          @include flex-align-center;
          gap: 8px;
          margin-bottom: 0;
        }
      }
    }
  }

  &_transaction {
    width: 100%;
    height: auto;

    ul.tab {
      width: 100%;
      height: 60px;
      background: #ff5112;
      border-radius: 5px;
      @include flex-center;
      margin-bottom: 15px;

      li {
        color: #fff;
        position: relative;
        padding: 0 30px;
        cursor: pointer;

        &:first-child::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 15px;
          background: #fff;
        }
      }
    }

    .coffee {
      width: 100%;
      height: auto;
      padding: 0 20px;
      @include flex-column;
      gap: 15px;

      li {
        width: 100%;
        height: auto;
        display: flex;
        gap: 18px;

        .img {
          width: auto;
          height: auto;
          @include flex-center;
        }

        .txt {
          width: 100%;
          height: auto;
          @include flex-column;
          justify-content: center;
          gap: 7px;

          p.name {
            font-size: 0.8125rem;
            color: #777;
          }

          p.product {
            font-size: 0.875rem;
            font-weight: 700;
          }

          p.money {
            font-size: 1rem;
            font-weight: 700;
            display: flex;
            gap: 5px;

            span {
              font-size: 0.8125rem;
              color: #ccc;
              text-decoration: line-through;
            }

            span.percent {
              color: #ef6855;
              font-size: 1rem;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
